<template>
  <a-modal :visible="true" title="编辑人员" :maskClosable="false" @ok="submit" @cancel="cancel" :width="600"
    :confirm-loading="confirmLoading" :closable="false">
    <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol" ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="手机号" required>
        {{ form.phone }}
      </a-form-model-item>
      <a-form-model-item label="角色：" style="margin-top: 20px" prop="roleIds">
        <a-select v-model="form.roleIds" placeholder="请选择角色类型" mode="multiple"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option v-for="item in roleTypeList" :value="item.roleTypeId" :key="item.roleTypeId">{{
            item.roleTypeName
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="部门：" prop="organizationId">
        <a-tree-select v-model="form.organizationId" :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
          :tree-data="departmentList" placeholder=" 请选择部门" tree-default-expand-all :replaceFields="{
            children: 'childList',
            title: 'organizationName',
            key: 'organizationId',
            value: 'organizationId',
          }" :getPopupContainer="(triggerNode) => {
  return triggerNode.parentNode || document.body;
}
  ">
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="飞书open_id：" prop="feishuid">
        <div style="display: flex;">
          <a-input v-model.trim="form.openId" placeholder=""  class="inputClass" />
          <a-button type="primary" @click="getFeishuOpenid" style="margin-left: 20px;"> <a-icon type="search" /> 通过手机号查询</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item label="服务项目：" style=" margin-top: 20px" prop="projectIdList">
        <a-select v-model="form.projectIdList" placeholder="请选择服务项目" mode="multiple"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option v-for="item in projectList" :value="item.projectId" :key="item.projectId">{{
            item.projectName
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import * as api from '@/api/demandPoolShunyi';
import { SUCCESS_CODE } from '@/config';
import { mapState } from 'vuex';
export default {
  name: 'AddStaff',
  props: {
    showEditStaff: {
      type: Boolean,
      default: false,
    },
    // 用户id
    userId: {
      type: String,
      debugger: '',
    },
  },
  computed: {
    searchDisabled() {
      return this.searchForm.account || this.searchForm.userName || this.searchForm.phoneNumber ? false : true;
    },
    ...mapState({
      roleTypeList: (state) => state.demandPool.roleTypeList,
      departmentList: (state) => state.demandPool.departmentList,
    }),
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 19 },
      confirmLoading: false, // 确定按钮loading
      form: {
        roleIds: [], // 角色类型集合
        organizationId: undefined, // 组织id
        userId: '', // 用户id
        phone: '', // 手机号
        openId:'',
        projectIdList:[],
      },
      rules: {
        roleIds: [{ required: true, message: '请选择角色类型', trigger: 'blur' }],
        organizationId: [{ required: true, message: '请选择部门', trigger: 'change' }],
        projectIdList: [{ required: true, message: '请选择服务项目', trigger: 'change' }],
      },
      projectList:[]
    };
  },
  methods: {
    // 提交
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.editStaff();
        }
      });
    },
    // 取消
    cancel() {
      this.$emit('update:show-edit-staff', false);
    },
    /**网络请求 */
    // 获取人员详情
    async getStaffDetail() {
      try {
        const result = await api.getStaffDetailForDemandPool(this.userId);
        if (result.code === SUCCESS_CODE) {
          this.staffDetailDispose(result.data);
          this.getTenantProject()
        }
      } catch (error) { }
    },
    // 编辑人员
    async editStaff() {
      try {
        this.confirmLoading = true;
        const result = await api.editStaffForDemandPool({ ...this.form, roleIds: this.form.roleIds.join(',') });
        this.confirmLoading = false;
        if (result.code === SUCCESS_CODE) {
          this.$message.success(result.msg);
          this.$emit('update:show-edit-staff', false);
          this.$emit('refreshStaffList');
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        console.log(error);
        this.$message.error('添加人员失败');
        this.confirmLoading = false;
      }
    },
    // 人员详情处理
    staffDetailDispose(data) {
      const roleIds = [];
      data.userRoleList.forEach((item) => {
        roleIds.push(item.roleTypeId);
      });
      this.form = {
        roleIds,
        organizationId: data.organizationId,
        userId: this.userId,
        phone: data.phone, // 手机号
        openId:data.openId,
        projectIdList:data.projectIdList
      };
    },
    // 获取飞书id
    async getFeishuOpenid() {
      let res = await api.getFeishuOpenid({phone:this.form.phone})
      this.form.openId = res.data
    },
    getTenantProject(){
      api.getTenantProject().then(res=>{
        this.projectList = res.data
        console.log(res,'>>>>>>>>>>>>>>>>>>>>>>>>>>.')
      })
    }
  },
  created() {
    this.getStaffDetail();
  },
};
</script>

<style lang="scss" scoped></style>
